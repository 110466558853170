import { Grid, Card, Container } from "@mui/material";
// EJEMPLO
import { createTheme, ThemeProvider } from "@mui/material/styles";
import TextSection from "../../components/TextSection";
import Carrusel from "../../pages/Principal/Secciones/Carrusel";
import Contacto from "../../pages/components/Contacto/Contacto";
import CumplidosPurp from "../../pages/components/CumplidosPurp";

// Ruta
// eslint-disable-next-line import/no-cycle
import routes from "../../routes_ZH/routes";
import footerRoutes from "../../routes_ZH/footer.routes";
import Footer from "../../components/Footer";
import Navbar from "../../components/Navbar";

// Sustituye MK
import Contenedor from "../../components/Contenedor";
import ContenedorBloques from "../../pages/components/ContendorBloques";
import RotatingCardSection from "../../pages/components/RotatingCardSection";

// Image
import servicio7 from "../../assets/images/PURP_WEB/1.INICIO/FINANCIERA.png";
import agro from "../../assets/images/PURP_WEB/1.INICIO/AGRONEGOCIOS.png";
import SyG from "../../assets/images/PURP_WEB/1.INICIO/GRANOS.png";
import Burbuja from "../../pages/components/BubbleWhatsApp";
import Barner from "../../assets/images/PURP_WEB/1.INICIO/SANIDADEINECUIDAD.jpg";
import Barner2 from "../../assets/images/PURP_WEB/1.INICIO/FGA.jpg";

const theme = createTheme();

theme.typography.h2 = {
  fontSize: "1.rem",
  "@media (min-width:600px)": {
    fontSize: "2rem",
  },
  [theme.breakpoints.up("lg")]: {
    fontSize: "2rem",
  },
  [theme.breakpoints.up("sm")]: {
    fontSize: "1.5rem",
  },
};
theme.typography.body2 = {
  fontSize: "0.75rem",
  "@media (min-width:600px)": {
    fontSize: "2rem",
  },
  [theme.breakpoints.up("lg")]: {
    fontSize: "1rem",
  },
  [theme.breakpoints.up("sm")]: {
    fontSize: "0.99rem",
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "1rem",
  },
};

function Presentation() {
  return (
    <Card>
      <Contenedor bgColor="white">
        <Navbar routes={routes} light sticky bgColor1="#494949" bgColor2="#000099" />
        <Carrusel text="是" palabras={["责任", "信任", "创新", "创新", "主动"]} />
        <ContenedorBloques bgColor="#ffffff">
          <Grid
            container
            item
            xs={12}
            justifyContent="center"
            alignItems="center"
            sx={{ mx: "auto" }}
            mt={{ xs: 0, md: 0, lg: 5 }}
            mb={{ xs: 1, md: 0, lg: 6 }}
          >
            <ThemeProvider theme={theme}>
              <TextSection
                variant="body1"
                sx={{ color: "#5B524D" }}
                textAlign="center"
                mt={2}
                mb={4}
                mx={{ xs: 0.5, lg: 1 }}
              >
                我們的宗旨：
                <span style={{ fontWeight: "bold", marginLeft: "5px" }}>
                  與地球相連，養活世界，創造價值和信任。
                </span>
              </TextSection>
            </ThemeProvider>
          </Grid>
        </ContenedorBloques>
        <ContenedorBloques bgColor="#ffffff" /* #f7f8f8 */>
          <ThemeProvider theme={theme}>
            <Grid
              container
              item
              xs={12}
              justifyContent="center"
              alignItems="center"
              sx={{ mx: "auto" }}
            >
              <TextSection variant="h2" sx={{ color: "#5B524D" }} textAlign="center">
                了解我們
              </TextSection>
            </Grid>
          </ThemeProvider>
          <Grid container spacing={3} alignContent="center" sx={{ mx: "auto" }} mb={-0.5} mt={1}>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <RotatingCardSection
                display="flex"
                TituloF={
                  <>
                    補給品
                    <br />
                  </>
                }
                ImgF={agro}
                TituloT={
                  <>
                    補給品
                    <br />
                  </>
                }
                Descripcion=""
                text="閱讀更多"
                ruta="/page/insumosZH"
                ImgT={agro}
                xs={0}
                sm={0}
                md={12}
                lg={12}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <RotatingCardSection
                display="flex"
                TituloF={<> 糧食</>}
                ImgF={SyG}
                TituloT={<>糧食</>}
                Descripcion=""
                text="閱讀更多"
                ruta="/page/serviciosZH"
                ImgT={SyG}
                xs={0}
                sm={0}
                md={12}
                lg={12}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <RotatingCardSection
                display="flex"
                TituloF={<>金融的</>}
                ImgF={servicio7}
                TituloT={<>金融的</>}
                Descripcion=""
                text="閱讀更多"
                ruta="/page/financieraZH"
                ImgT={servicio7}
                xs={0}
                sm={0}
                md={12}
                lg={12}
              />
            </Grid>
          </Grid>
        </ContenedorBloques>
        {/* BARNER DE COSECHA */}
        {/* BARNER DE COSECHA */}
        <Contenedor mt={10} mb={8}>
          <Container>
            <ThemeProvider theme={theme}>
              <TextSection variant="h2" sx={{ color: "#5B524D" }} textAlign="center" mb={6}>
                即將舉行的活動
              </TextSection>
            </ThemeProvider>
            <Grid direction="center" textAlign="center" display="flex">
              <Contenedor>
                <a
                  href="https://congresosanidadinocuidadagro.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Contenedor
                    borderRadius="md"
                    coloredShadow="black"
                    component="img"
                    src={Barner}
                    Width="90%"
                    sx={{
                      margin: "0.5rem",
                      transition: "transform 0.2s", // tiempo de transición
                      "&:hover": {
                        transform: "scale(1.1)", // la imagen al 110% cuando el cursor está encima
                      },
                    }}
                  />
                </a>
              </Contenedor>
              <Contenedor>
                <a href="https://fga.org.mx/" target="_blank" rel="noopener noreferrer">
                  <Contenedor
                    borderRadius="md"
                    coloredShadow="black"
                    component="img"
                    src={Barner2}
                    Width="90%"
                    sx={{
                      margin: "0.5rem",
                      transition: "transform 0.2s", // tiempo de transición
                      "&:hover": {
                        transform: "scale(1.1)", // la imagen al 110% cuando el cursor está encima
                      },
                    }}
                  />
                </a>
              </Contenedor>
            </Grid>
          </Container>
        </Contenedor>
        <CumplidosPurp texto="35 年累積信任" />
        <Contacto
          titulo="聯絡我們"
          nombre="姓名"
          asunto="事务"
          correo="邮件"
          telefono="电话"
          mensaje="信息"
          enviar="发送"
          phNombre="全名"
          phAsunto="您从事什么业务"
          phCorreo="你的电子邮箱是什么"
          phTelefono="你的号码是多少"
          phMensaje="写下您的留言..."
        />
        <Burbuja
          sub="公司账户"
          text1="你好 👋"
          text2="我怎么帮你？"
          phMensaje="写一个信息"
          btn="发送"
        />
        <Contenedor pt={6} px={1} mt={6}>
          <Footer content={footerRoutes} ColorFondo="#5B524D" />
        </Contenedor>
        <ContenedorBloques bgColor="#FFCC00" ContainerSize={0.5} />
      </Contenedor>
    </Card>
  );
}

export default Presentation;
