import { Grid, Card, Container } from "@mui/material";
// EJEMPLO
import { createTheme, ThemeProvider } from "@mui/material/styles";
import TextSection from "../../components/TextSection";
// import Advertencia from "../components/Advertencia";
/* import Modal from "../../components/Modal"; */
import Carrusel from "./Secciones/Carrusel";
import Contacto from "../components/Contacto/Contacto";
import CumplidosPurp from "../components/CumplidosPurp";

// Ruta
// eslint-disable-next-line import/no-cycle
import routes from "../../routes/routes";
import footerRoutes from "../../routes/footer.routes";
import Footer from "../../components/Footer";
import Navbar from "../../components/Navbar";

// Sustituye MK
import Contenedor from "../../components/Contenedor";
import ContenedorBloques from "../components/ContendorBloques";
import RotatingCardSection from "../components/RotatingCardSection";

// Image
import servicio7 from "../../assets/images/PURP_WEB/1.INICIO/FINANCIERA.png";
import agro from "../../assets/images/PURP_WEB/1.INICIO/AGRONEGOCIOS.png";
import SyG from "../../assets/images/PURP_WEB/1.INICIO/GRANOS.png";
import Burbuja from "../components/BubbleWhatsApp";
/* import Aviso from "../../assets/images/PURP_WEB/1.INICIO/AVISO.png"; */
import Barner2 from "../../assets/images/PURP_WEB/1.INICIO/FGA.jpg";

const theme = createTheme();

theme.typography.h2 = {
  fontSize: "1.rem",
  "@media (min-width:600px)": {
    fontSize: "2rem",
  },
  [theme.breakpoints.up("lg")]: {
    fontSize: "2rem",
  },
  [theme.breakpoints.up("sm")]: {
    fontSize: "1.5rem",
  },
};
theme.typography.body1 = {
  fontSize: "1rem",
  "@media (min-width:600px)": {
    fontSize: "2rem",
  },
  [theme.breakpoints.up("lg")]: {
    fontSize: "1.3rem",
  },
  [theme.breakpoints.up("sm")]: {
    fontSize: "1.2rem",
  },
};
theme.typography.body2 = {
  fontSize: "0.75rem",
  "@media (min-width:600px)": {
    fontSize: "2rem",
  },
  [theme.breakpoints.up("lg")]: {
    fontSize: "1rem",
  },
  [theme.breakpoints.up("sm")]: {
    fontSize: "0.99rem",
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "1rem",
  },
};

function Presentation() {
  return (
    <Card>
      <Contenedor bgColor="white">
        <Navbar routes={routes} light sticky bgColor1="#494949" bgColor2="#000099" />
        {/* <Modal modalShow titulo="Aviso" Imagen={Aviso} label="Cerrar" /> */}
        <Carrusel
          text="Somos"
          palabras={["Responsabilidad", "Confianza", "Innovación", "Pasión", "Proactividad"]}
        />
        <ContenedorBloques bgColor="#ffffff">
          <Grid
            container
            item
            xs={12}
            justifyContent="center"
            alignItems="center"
            sx={{ mx: "auto" }}
            mt={{ xs: 0, md: 0, lg: 5 }}
            mb={{ xs: 1, md: 0, lg: 6 }}
          >
            <ThemeProvider theme={theme}>
              <TextSection
                variant="body1"
                sx={{ color: "#5B524D" }}
                textAlign="center"
                mt={2}
                mb={4}
                mx={{ xs: 0.5, lg: 1 }}
              >
                Nuestro propósito:
                <span style={{ fontWeight: "bold", marginLeft: "5px" }}>
                  Conectar con la tierra y alimentar al mundo, creando valor y confianza.
                </span>
              </TextSection>
            </ThemeProvider>
          </Grid>
        </ContenedorBloques>

        <ContenedorBloques bgColor="#ffffff">
          <ThemeProvider theme={theme}>
            <Grid
              container
              item
              xs={12}
              justifyContent="center"
              alignItems="center"
              sx={{ mx: "auto" }}
            >
              <TextSection variant="h2" sx={{ color: "#5B524D" }} textAlign="center">
                CONÓCENOS
              </TextSection>
            </Grid>
          </ThemeProvider>
          <Grid container spacing={3} alignContent="center" sx={{ mx: "auto" }} mb={-0.5} mt={1}>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <RotatingCardSection
                display="flex"
                TituloF={
                  <>
                    INSUMOS
                    <br />
                  </>
                }
                ImgF={agro}
                TituloT={
                  <>
                    INSUMOS
                    <br />
                  </>
                }
                Descripcion=""
                text="Leer más"
                ruta="/pages/Insumos_agricolas"
                ImgT={agro}
                xs={12}
                sm={12}
                md={12}
                lg={12}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <RotatingCardSection
                display="flex"
                TituloF={<> GRANOS</>}
                ImgF={SyG}
                TituloT={<>GRANOS</>}
                Descripcion=""
                text="Leer más"
                ruta="/pages/Servicios_y_granos"
                ImgT={SyG}
                xs={12}
                sm={12}
                md={12}
                lg={12}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <RotatingCardSection
                display="flex"
                TituloF={<>FINANCIERA</>}
                ImgF={servicio7}
                TituloT={<>FINANCIERA</>}
                Descripcion=""
                text="Leer más"
                ruta="/pages/Financiera_purp"
                ImgT={servicio7}
                xs={12}
                sm={12}
                md={12}
                lg={12}
              />
            </Grid>
          </Grid>
        </ContenedorBloques>
        {/* BARNER DE COSECHA */}
        <Contenedor mt={10} mb={8}>
          <Container>
            <ThemeProvider theme={theme}>
              <TextSection variant="h2" sx={{ color: "#5B524D" }} textAlign="center" mb={6}>
                PRÓXIMOS EVENTOS
              </TextSection>
            </ThemeProvider>
            <Grid direction="center" textAlign="center" display="flex">
              <Contenedor>
                <a href="https://fga.org.mx/" target="_blank" rel="noopener noreferrer">
                  <Contenedor
                    borderRadius="md"
                    coloredShadow="black"
                    component="img"
                    src={Barner2}
                    Width="90%"
                    sx={{
                      margin: "0.5rem",
                      transition: "transform 0.2s", // tiempo de transición
                      "&:hover": {
                        transform: "scale(1.1)", // la imagen al 110% cuando el cursor está encima
                      },
                    }}
                  />
                </a>
              </Contenedor>
            </Grid>
          </Container>
        </Contenedor>
        <CumplidosPurp texto="35 Años Generando Confianza" />
        <Contacto
          titulo="CONTÁCTANOS"
          nombre="NOMBRE"
          asunto="ASUNTO"
          correo="CORREO"
          telefono="TELÉFONO"
          mensaje="MENSAJE"
          enviar="ENVIAR"
          phNombre="Nombre completo"
          phAsunto="¿Cuál es su asunto?"
          phCorreo="¿Cuál es su correo?"
          phTelefono="¿Cuál es su número?"
          phMensaje="Escriba su mensaje..."
        />
        <Burbuja
          sub="Cuenta de empresa"
          text1="Hola 👋"
          text2="Como te puedo ayudar?"
          phMensaje="Escribe un mensaje"
          btn="Enviar"
        />
        <Contenedor pt={6} px={1} mt={6}>
          <Footer content={footerRoutes} ColorFondo="#5B524D" />
        </Contenedor>
        <ContenedorBloques bgColor="#FFCC00" ContainerSize={0.5} />
      </Contenedor>
    </Card>
  );
}

export default Presentation;
